<template>
  <div class="flex" v-if="visiblePages.length>1">
    <Button
            v-long-click
            @single-click="previous"
            @long-click="toStart"
            @long-hold="previousLongHold = $event.detail"
            severity="secondary" style="width: 52px"
            class="border-grey br-r0 br-l3" outlined
            :icon="`pi pi-${previousLongHold ? 'angle-double' : 'arrow'}-left font-20`"/>
    <Button severity="secondary" outlined @click="goTo(page)"
            :class="props.currentPage===page?'hover-background':''"
            class="br-0 ml--1 fw-500" v-for="page in visiblePages"
            :key="page" :label="page.toString()"/>
    <Button v-long-click @single-click="next" @long-click="toEnd" @long-hold="nextLongHold = $event.detail"
            severity="secondary" style="width: 52px" class="border-grey br-l0 br-r3 ml--1" outlined
            :icon="`pi pi-${nextLongHold ? 'angle-double' : 'arrow'}-right font-20`"/>
  </div>
</template>

<script setup>
import {computed, ref} from "vue";
import dayjs from "dayjs";

const now = ref(dayjs())
setInterval(() => now.value = dayjs(), 100)


const pressedPrevious = ref(null)
const pressedNext = ref(null)

const nextMode = computed(() => {
  const date1 = now.value
  if (pressedNext.value == null) return false
  else return date1.diff(pressedNext.value) > 500
})

const previousMode = computed(() => {
  const date1 = now.value
  if (pressedPrevious.value == null) return false
  else return date1.diff(pressedPrevious.value) > 500
})


const previousLongHold = ref(null)
const nextLongHold = ref(null)

const props = defineProps({
  pageSizeOptions: {
    type: Array,
    required: true
  },
  pageSize: {
    type: Number,
    required: true
  },
  currentPage: {
    type: Number,
    required: true
  },
  totalPages: {
    type: Number,
    required: true
  },
  pageLinkSize: {
    type: Number,
    default: 5
  }
})

const visiblePagesDelta = ref(1)

const goTo = (page) => {
  emits('go-to', page)
  visiblePagesDelta.value = 0
}

const visiblePages = computed(() => {
  const numberOfPages = props.totalPages;
  const visiblePages = Math.min(props.pageLinkSize, numberOfPages);
  let start = Math.max(0, Math.ceil(props.currentPage - visiblePages / 2));
  let end = Math.min(numberOfPages - 1, start + visiblePages - 1);
  const delta = props.pageLinkSize - (end - start + 1);
  start = Math.max(0, start - delta);
  let pageLinks = [];
  for (let i = start; i <= end; i++) {
    pageLinks.push(i+ visiblePagesDelta.value);
  }
  return pageLinks;
})
const next = () => {
  if (visiblePagesDelta.value + props.currentPage <= props.totalPages - props.pageLinkSize)
    visiblePagesDelta.value++
}

const toEnd = () => visiblePagesDelta.value = props.totalPages - props.pageLinkSize

const previous = () => {
  if (visiblePagesDelta.value + props.currentPage > 2)
    visiblePagesDelta.value--
}
const toStart = () => visiblePagesDelta.value = 1


const emits = defineEmits(['go-to'])

</script>

<style lang="stylus" scoped>
@import '@/styles/variables.styl'
.ml--1
  margin-left -1px

:deep(.p-button-pagination) {
  background-color var(--surface-0)
  color var(--x-color-text)
}
</style>