<template>
  <div class="flex main" v-if="!hideContent">
    <Modals/>
    <Sidebar/>
    <div class="flex-column flex" :class="uiStore.smAndDown?'w-full':'container'">
      <MobileHeader v-if="uiStore.smAndDown"/>
      <slot/>

    </div>

  </div>
</template>

<script setup>
import { notReqiredAuth, router} from '@/router'
import Sidebar from '@/components/Sidebar'
import Modals from '@/components/Modals'
import MobileHeader from "@/components/MobileHeader";
import {useUiStore} from "@/store";
import DataTableFooter from "@/components/messages/MessagesDataTableFooter"
import {onMounted} from "vue";
import {useRegionStore} from "@/store/models/region";
import {useThemesStore} from "@/store/models/themes";
import {useKeywordsStore} from "@/store/models/keywords";
const hideContent = notReqiredAuth(router.currentRoute.value)
const uiStore = useUiStore()
const regionStore = useRegionStore()
const themesStore = useThemesStore()
const keywordsStore = useKeywordsStore()
onMounted(()=>{
  regionStore.loadRegionsList()
  themesStore.loadThemesList()
  keywordsStore.loadKeywordsList()
})
</script>

<style lang="stylus" scoped>
.main
  width 100vw
  height 100vh
  margin 0
  padding 0
  background: var(--surface-0);

.container
  width calc(100vw - 93px)
</style>