import {defineStore} from 'pinia'
import {inject} from 'vue'
import RegionsService from "@/services/regions.service";
import {useAuthStore} from "@/store";

let selectedRegions;
try {
    selectedRegions = JSON.parse(localStorage.getItem('regions'));
} catch {
    selectedRegions = null
}

let selectedRegion;
try {
    selectedRegion = JSON.parse(localStorage.getItem('region'));
} catch {
    selectedRegion = null
}


export const useRegionStore = defineStore("region", {
    state: () => ({
        selectedRegions: selectedRegions || [],
        selectedRegion,
    }),
    getters: {
        regionsOptions() {
            return this.objects?.map(x => ({name: x.title, value: x.id}))
        },
        mainRegion() {
            const authStore = useAuthStore()
            return authStore.fullUser?.main_region
        },

    },
    actions: {
        SET_SELECTED_REGIONS(val) {
            this.selectedRegions = val
        },
        SET_SELECTED_REGION(val) {
            this.selectedRegion = val
        },
        loadRegionsList() {
            this.RESET_OBJECTS()
            this.service.list({page: this.currentPage, page_size: this.pageSize}).then((res) => {
                this.OBJECTS_LOADED(res.data)
                this.SET_TOTAL_PAGES(0)
                if (this.selectedRegions && this.selectedRegions.length === 0 && res.data.length > 0) {
                    this.selectRegion(res.data.map(x => x.id)[0])
                }
            })
        },
        selectRegions(val) {
            this.SET_SELECTED_REGIONS(val)
            localStorage.setItem("regions", JSON.stringify(val))
            if (val && val.length > 0 && this.selectedRegion !== val[0]) {
                this.selectRegion(val[0])
            }
        },
        selectRegion(val) {
            this.SET_SELECTED_REGION(val)
            localStorage.setItem("region", JSON.stringify(val))
            if (!this.selectedRegions || (this.selectedRegions && this.selectedRegions.filter(x => x === val).length === 0))
                this.selectRegions([val])
        }
    },
    model: {
        service: RegionsService,
    },
    paginator: {}
})