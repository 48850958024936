<template>
  <div class="card flex justify-content-center">
    <Button  @click="visible = true" :disabled="props.ids.length===0" severity="secondary" outlined
            style="padding: 10px !important" class="br-3 label-color border-grey flex">
      <img alt="" src="/img/icons/basic/trash.svg"/>
      <div v-if="!uiStore.smAndDown" class="ml-2">Удалить</div>
    </Button>

    <Dialog v-model:visible="visible" modal class="border-round" header="Header"
            :style="{ width: '400px' }">
      <template #header>
        <img alt="Удалить" src="/img/icons/basic/trash-filled.svg"/>
      </template>
      <div class="mb-5 label-color f-20 fw-500">Удалить {{ props.ids.length>1?'выбранные сообщения':'сообщение' }}</div>
      <div class="flex gap-2">
        <Button @click="cancel" class="w-50 flex justify-content-center label-color" outlined
                severity="secondary" label="Отмена"></Button>
        <Button @click="apply" class="w-50 flex justify-content-center">Подтвердить</Button>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import {ref} from "vue";
import messagesService from "@/services/messages.service";
import {Tones} from "@/enums/filters/tones";
import {useMessagesStore} from "@/store/models/messages";
import {useUiStore} from "@/store";

const messagesStore = useMessagesStore()
const uiStore = useUiStore()
const props = defineProps({
  ids: {
    type: Array,
    required: true,
  }
})


const visible = ref(false);
const cancel = () => {
  visible.value = false
}
const apply = async () => {
  // const response = await messagesService.patch(props.id, {true_tone: null}, {serializer: 'list'});
  // messagesStore.PUT_ONE({id: response.data.id, data: response.data})
  //
  // visible.value = false
  console.log('deleting')
}
</script>

<style lang="stylus" scoped>

</style>