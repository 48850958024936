import {apiService} from '@/services';

const API_URL = 'keywords/';

class KeywordService {
    list(params) {
        return apiService.get(`${API_URL}keywords/`, {params});
    }

    get(id) {
        const params = {regions: [store.getters['regions/selectedRegion']]};
        return apiService.get(`${API_URL}keywords/${id}/`, {params});
    }

    create(data) {
        return apiService.post(`${API_URL}keywords/`, data);
    }

    update(data) {
        const params = {regions: [store.getters['regions/selectedRegion']]};
        return apiService.put(`${API_URL}keywords/${data.id}/`, data, {params});
    }

    info(params) {
        return apiService.get(`${API_URL}info/`, {params});
    }

    count(params) {
        return apiService.get(`${API_URL}count/`, {params});
    }

    perDay(params) {
        return apiService.get(`${API_URL}per-day/`, {params});
    }

    import(data) {
        return apiService.post(`${API_URL}keywords/import_csv/`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }
}

export default new KeywordService();