import { defineStore } from "pinia";


export const useModalStore = defineStore("modal", {
    state: () => ({
        open: [],
        activeChatId: null,
    }),
    getters: {
        active: () => (this.open.length > 0 ? this.open[0] : null),
    },
    actions: {
        OPEN(name) {
            this.open.unshift(name)
        },
        CLOSE(name) {
            this.open = this.open.filter((x) => x !== name)
        },
        CLOSE_ALL() {
            this.open = []
        },
        SET_ACTIVE_CHAT_ID(id) {
            this.activeChatId = id
        }
    },
});

