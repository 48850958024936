<template>
<div class="auth">
  <slot/>
  <img src="../assets/mobilization-grey.svg" alt=""/>
</div>
</template>

<script>
export default {
  name: "AppLayoutAuth"
}
</script>

<style lang="stylus" scoped>
.auth
  font-family 'Inter'
  height 100vh
  width 100%
  display flex
  justify-content center
  align-items center

img
  position absolute
  left 50%
  bottom 32px
  transform translateX(-50%)
</style>