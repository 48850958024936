import {createApp, ref} from "vue";
import App from "./App.vue";
import {router} from "./router";
import {pinia} from "./store";
import PrimeVue from 'primevue/config';
import '/node_modules/primeflex/primeflex.css'
import './styles/app.styl'
import {ErrorMessage, Field, Form} from 'vee-validate';
// import './plugins/vee-validate'
import Button from "primevue/button"
import Divider from 'primevue/divider';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import Sidebar from 'primevue/sidebar';
import MultiSelect from 'primevue/multiselect';
import Checkbox from 'primevue/checkbox';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import VirtualScroller from 'primevue/virtualscroller';
import Toast from 'primevue/toast';
import mitt from 'mitt';
import 'dayjs/locale/ru'
import dayjs from 'dayjs'
import vClickOutside from "click-outside-vue3"
import locale from 'primelocale/ru.json'
import ToastService from 'primevue/toastservice';

const emitter = mitt();
const app = createApp(App)
app.use(vClickOutside)
dayjs.locale('ru')

app.directive('long-click', {
    mounted: (el, binding) => {
        const duration = binding.value?.duration || 500
        console.log('duration', duration)
        const pressed = ref(null)
        const longHold = ref(false)

        function startInterval() {
            el.dataset.longClickIntervalId = setInterval(() => {
                const now = dayjs()
                const newLongHold = now.diff(pressed.value) > duration;
                if (newLongHold !== longHold.value) {
                    el.dispatchEvent(new CustomEvent('long-hold', {detail: newLongHold}))
                }
                longHold.value = newLongHold
            }, 100).toString()
        }

        function stopInterval() {
            if (el.dataset.longClickIntervalId) {
                clearInterval(el.dataset.longClickIntervalId)
            }
            el.dispatchEvent(new CustomEvent('long-hold', {detail: false}))
        }

        el.addEventListener('mousedown', (event) => {
            pressed.value = dayjs()
            startInterval()
        })
        el.addEventListener('mouseup', (event) => {
            let now = dayjs()
            if (now.diff(pressed.value) > duration) el.dispatchEvent(new CustomEvent('long-click'))
            else el.dispatchEvent(new CustomEvent('single-click'))
            pressed.value = null
            stopInterval()
        })
    },
    beforeUnmount(el) {
        if (el.dataset.longClickIntervalId) {
            clearInterval(el.dataset.longClickIntervalId)
        }
    }
})
app.provide('emitter', emitter);
app.component('VirtualScroller', VirtualScroller)
app.component('Toast', Toast)
app.component('Dropdown', Dropdown)
app.component('ProgressSpinner', ProgressSpinner)
app.component('MultiSelect', MultiSelect)
app.component('InputNumber', InputNumber)
app.component('InputMask', InputMask)
app.component('Checkbox', Checkbox)
app.component('Calendar', Calendar)
app.component('Sidebar', Sidebar)
app.component('TabPanel', TabPanel)
app.component('Column', Column)
app.component('DataTable', DataTable)
app.component('Dialog', Dialog)
app.component('TabView', TabView)
app.component('InputText', InputText)
app.component('Divider', Divider)
app.component('Button', Button);
app.component('Form', Form);
app.component('Field', Field);
app.component('ErrorMessage', ErrorMessage);
app.use(PrimeVue, {locale: locale.ru, ripple: false});
app.use(router);
app.use(pinia);
app.use(ToastService);
app.mount("#app");

export default app