import {createPinia} from "pinia";
import {PiniaLogger} from "pinia-logger";
import {BackendModelPlugin, BackendPaginatorPlugin} from "@/plugins/pinia";
import { PiniaLoading } from 'pinia-plugin-loading'
export const pinia = createPinia();
pinia.use(
    PiniaLogger({
        expanded: false,
        // disabled: process.env.MODE === 'production',
    })
);
pinia.use(
    BackendModelPlugin()
)

pinia.use(
    PiniaLoading
)

pinia.use(
    BackendPaginatorPlugin()
)

export * from "./ui";
export * from "./auth";
