import {apiService} from '@/services';

const API_URL = 'themes/';

class ThemesService {
    list(params) {
        return apiService.get(`${API_URL}themes/`, {params});
    }

    get(id) {
        return apiService.get(`${API_URL}themes/${id}/`,);
    }

    create(data) {
        return apiService.post(`${API_URL}themes/`, data);
    }

    popular(params) {
        return apiService.get(`${API_URL}popular/`, {params});
    }
}

export default new ThemesService();