import {computed} from "vue";

export const Tones = {
    NEG: 'neg',
    POS: 'pos',
    NEUTRAL: 'neutral',
}

export const allTones = [
    Tones.NEG,
    Tones.POS,
    Tones.NEUTRAL,
]

export const allTonesOptions = computed(()=>{
    return allTones.map((x)=>{
        return {
            name:TonesReadable(x),
            value:x
        }
    })
})

export function TonesReadable(media) {
    return (
        {
            [Tones.NEG]: 'Негатив',
            [Tones.POS]: 'Позитив',
            [Tones.NEUTRAL]: 'Нейтрально',
        }[media] || 'Неизвестный тон сообщения'
    )
}


