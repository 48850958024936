import {defineStore} from "pinia";
import AuthService from '@/services/auth.service';
import UserService from "@/services/user.service";

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? {status: {loggedIn: true}, user}
    : {status: {loggedIn: false}, user: null};

export const useAuthStore = defineStore("auth", {
    state: () => ({
        ...initialState,
        fullUser: null,
    }),
    getters: {
        userData() {
            if (this.user?.access) return JSON.parse(atob(this.user.access.split(".")[1]))
            else return null
        },
        isSuperuser() {
            !!this.fullUser?.is_superuser
        },
        role() {
            this.fullUser?.role
        },
        mainRegion() {
            this.fullUser?.main_region
        },
        id() {
            this.fullUser?.id
        },
    },
    actions: {
        telegramLogin(user) {
            return AuthService.telegramLogin(user).then(
                user => {
                    this.loginSuccess(user);
                    return user;
                },
            );
        },
        login({user, code}) {
            return AuthService.login(user, code).then(
                user => {
                    this.loginSuccess(user);
                    return user;
                },
                error => {
                console.log('auth error:', error)
                }
            );
        },
        logout() {
            AuthService.logout();
            this.status.loggedIn = false;
            this.user = null;
        },
        getFullUser() {
            UserService.get(this.userData.user_id).then((res) => {
                this.setFullUser(res.data)
            })
        },
        async update(data) {
            await UserService.partial({id: this.userData.user_id, ...data})
            await this.getFullUser()
        },
        loginSuccess(user) {
            this.status.loggedIn = true;
            this.user = user;
            this.getFullUser()
        },
        loginFailure() {
            this.status.loggedIn = false;
            this.user = null;
        },
        setFullUser(user) {
            this.fullUser = user
        }
    },
});
