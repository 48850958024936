import Vue, {ref} from "vue";
import {baseURL, paramsSerializer} from "@/services"
import {useRegionStore} from "@/store/models/region";


export default function BackendModelPlugin() {
    return (context) => {
        if ('model' in context.options) {
            return {
                service: context.options.model.service,
                objects: ref([]),
                activeObjectDetail:ref(null),
                activeObjectId:ref(null),
                listLoading: false,
                extraListParams: {},
                RESET_OBJECTS() {
                    this.objects = []
                },
                LIST_LOADING() {
                    this.listLoading = true
                },
                LIST_LOADED() {
                    this.listLoading = false
                },
                OBJECTS_LOADED(keywords) {
                    this.objects = keywords
                },
                ACTIVE_OBJECT_LOADED(keyword) {
                    this.activeObject = keyword
                },
                UPDATE_EXTRA_LIST_PARAMS(params) {
                    this.extraListParams = Object.assign({}, this.extraListParams, params)
                },
                SET_EXTRA_LIST_PARAMS(params) {
                    this.extraListParams = params
                },
                PUT_ONE({id, data}) {
                    const foundedIdx = this.objects.findIndex(x => x.id === id)
                    this.objects[foundedIdx] = data
                },
                clearActive(){
                    this.activeObject = {}
                    this.activeObjectId = null
                },
                buildListParams(extra) {
                    // console.log("STATE EXTRA", Object.values(state.extraListParams))
                    const params = {page: this.currentPage, page_size: this.pageSize};
                    const regionStore = useRegionStore()
                    if (this.extraListParams) {
                        Object.assign(params, this.extraListParams)
                    }
                    if (this.useRegions) {
                        params.regions = regionStore.selectedRegions
                    } else if (this.useRegion) {
                        params.regions = regionStore.selectedRegion
                    }
                    Object.assign(params, extra)
                    return params
                },
                async loadList(extra) {
                    if (!extra?._hiddenReload) {
                        this.RESET_OBJECTS()
                        this.LIST_LOADING()
                    }

                    this.service.list(await this.buildListParams(extra)).then((res) => {
                        this.OBJECTS_LOADED(res.data.results)
                        this.SET_TOTAL_PAGES(res.data.total_pages)
                        this.LIST_LOADED()
                    })
                },
                async exportList({extra, filename}) {
                    if (!filename) filename = "export"
                    const params = await this.buildListParams({...extra, format: "xlsx"});
                    window.location.href = baseURL + this.service.listUrl + "?" + paramsSerializer(params)
                },
                loadOne(id) {
                    this.ACTIVE_OBJECT_LOADED(null)
                    if (id) {
                        this.service.get(id).then((res) => {
                            this.ACTIVE_OBJECT_LOADED(res.data)
                        })
                    }
                },
                create(data) {
                    return this.service.create(data)
                },
                update(data) {
                    return this.service.update(data)
                }
            }
        }
    }
}