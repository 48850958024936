<template>
<Dialog v-model:visible="isActive" modal  class="modals-wrapper"></Dialog>
</template>

<script setup>
import {computed} from 'vue'
import {useModalStore} from "@/store/modals";
const modalStore = useModalStore()
const props = defineProps({
  name: {
    type: String,
    required: true,
  }
})
const isActive=computed({
  get() {
  return modalStore.open.includes(props.name)
},
set(val) {
  if (val) modalStore.OPEN(props.name)
  else modalStore.CLOSE(props.name)
}
})
</script>

<style lang="stylus" scoped>
.modals-wrapper
  position absolute
  top 0
  left 0
  width 100vw
  height 100vh
</style>