import {createRouter, createWebHashHistory} from 'vue-router'
import {useUiStore} from "@/store";


const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: {name: "Dashboard"},
    meta: {
      layout: 'main'
    }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('../views/Auth'),
    meta: {
      layout: 'auth'
    }
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import('../views/Registration'),
    meta: {
      layout: 'auth'
    }
  },
  {
    path: '/password',
    name: 'Password Recovery',
    component: () => import('../views/PasswordRecovery'),
    meta: {
      layout: 'auth'
    }
  },
  {
    path: '/messages',
    name: 'Messages',
    component: () => import('../views/MessagesAnalytics'),
    meta: {
      layout: 'main'
    }
  },
  {
    path: '/chats',
    name: 'Chats',
    component: () => import('../views/ChatsAnalytics'),
    meta: {
      layout: 'main'
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard'),
    meta: {
      layout: 'main'
    }
  },
]

const publicPages = ['/auth','/password','/registration'];

export const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export function notReqiredAuth(to){
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  return authRequired && !loggedIn
}


router.beforeEach((to, from, next) => {
  // trying to access a restricted page + not logged in
  // redirect to login page
  const authNotRequired = publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  if (notReqiredAuth(to)) {
    router.push({name: 'Auth'})
  }
  else if (authNotRequired&&loggedIn){
    router.push({name: 'Home'})
  }
  else {
    next();
  }
});

router.afterEach(() => {
  let uiStore = useUiStore()
  uiStore.closeSidebar()
})


