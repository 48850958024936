import ThemesService from "@/services/themes.service";
import {defineStore} from 'pinia'


export const useThemesStore = defineStore('themes',{
    state: ()=>({
        useRegion: true,
    }),
    getters:{
        themesOptions(){
            return this.objects.map(x => ({text: x.title, value: x.id}))

        },
    },
    actions:{
        async loadThemesList(extra) {
            if (!extra?._hiddenReload)
                this.RESET_OBJECTS()
            this.service.list(await this.buildListParams(extra)).then((res) => {
                this.OBJECTS_LOADED(res.data)
            })
        }
    },
    model:{
        service: ThemesService,
    }
})