<template>
<div class="header">
  <Button class="m-5" @click.stop.prevent="toggleSidebar" severity="secondary" text icon="pi pi-bars"></Button>
</div>
</template>

<script setup>
import {useUiStore} from "@/store";
const uiStore = useUiStore()

function toggleSidebar() {
  uiStore.isSidebarActive = !uiStore.isSidebarActive
}
</script>

<style lang="stylus" scoped>
@import '@/styles/variables.styl'
.header
  width 100vw
  background $cBackground
</style>