import {apiService} from '@/services';

const API_URL = 'regions/region/';

class RegionsService {
    async list(params) {
        let list = await apiService.get(`${API_URL}`, {params});
        return list
    }

    get(id) {
        return apiService.get(`${API_URL}${id}/`,);
    }

    create(data) {
        return apiService.post(`${API_URL}`, data);
    }
}

export default new RegionsService();
