import keywordsService from "@/services/keywords.service";
import {defineStore} from 'pinia'

export const useKeywordsStore = defineStore('keywords', {
    state: ()=>({
        useRegion: true,
    }),
    getters:{
        keywordsOptions() {
            return this.objects?.map(x => ({name: x.title, value: x.id}))
        },
    },
    actions: {
        async loadKeywordsList(extra) {
            if (!extra?._hiddenReload)
                this.RESET_OBJECTS()
            this.service.list(await this.buildListParams(extra)).then((res) => {
                this.OBJECTS_LOADED(res.data.results)
            })
        }
    },
    model: {
        service: keywordsService,
    }
})