import {apiService} from '@/services';

const API_URL = 'messages/';

class MessagesService {
    listUrl = `${API_URL}message/`

    list(params, config) {
        return apiService({
            method: "get",
            url: this.listUrl,
            params,
            ...config
        })
    }

    patch(id, data, params) {
        return apiService({
            method: "patch",
            url: `${API_URL}message/${id}/`,
            data,
            params
        })
    }

    bulkUpdate(data) {
        return apiService({
            method: "post",
            url: `${API_URL}message/bulk-update/`,
            data,
        })
    }

    checkNew(params) {
        return apiService.get(`${API_URL}message/check-new/`, {params});
    }

    get(id) {
        return apiService.get(`${API_URL}message/${id}/`,);
    }

    recent(id, params) {
        return apiService.get(`${API_URL}message/${id}/recent/`, {params});
    }

    info(params) {
        return apiService.get(`${API_URL}info/`, {params});
    }

    confirmAlert(id, params) {
        return apiService.post(`${API_URL}message/${id}/confirm-alert/`, {}, {params});
    }

    themesEmotionalSummary(params) {
        return apiService.get(`${API_URL}message/themes-emotional-summary/`,{params});
    }
}

export default new MessagesService();