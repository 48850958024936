<template>
  <div>
    <AppLayout class="overflow-hidden">
      <router-view/>
    </AppLayout>
    <Toasts/>
  </div>
</template>

<script setup>
import AppLayout from '@/layouts/AppLayout.vue'
import {nextTick, onMounted} from "vue";
import {useUiStore} from "@/store";
import {init} from "@/utils";
import {useAuthStore} from "@/store/auth";
import {router} from "@/router";
import Toasts from "@/components/Toasts";
import {useToastsStore} from "@/store/toasts";
onMounted(async () => {
  const authStore = useAuthStore()
  const toastsStore = useToastsStore()
  if (authStore.user)  authStore.getFullUser()
  const uiStore = useUiStore()


  await init()

  await nextTick(async () => {
    uiStore.setIsGlobalLoaderActive(false)
  })
  if (!authStore.user) {
      await router.push({name:'Auth'})
  }
})
</script>

<style lang="stylus">
@import url(//fonts.googleapis.com/css?family=Inter:700,300,600,800,400&subset=cyrillic,cyrillic-ext,latin);
@import '@/styles/variables.styl'
body
  font-family 'Inter' !important
  margin 0
  padding 0

::-webkit-scrollbar
  width 8px !important
  height 8px !important

.p-tabview-nav-link
  background transparent !important

::-webkit-scrollbar-track
  background transparent !important
  padding 4px

::-webkit-scrollbar-corner { background: var(--surface-0) !important }

::-webkit-scrollbar-thumb
  border 4px var(--surface-500) solid
  background-color var(--surface-500) !important
  border-radius 8px !important

.p-sidebar-content
  padding 0 24px 24px 24px !important

.p-sidebar-header
  justify-content space-between !important

.p-button-outlined
  height 40px

.p-button-label, .p-column-title, .p-tabview-title
  font-weight 500 !important

.p-button
  font-weight 500 !important
  border-radius 8px !important

.p-multiselect, .p-dropdown
  border-radius 8px !important

.p-inputtext
  border-radius 8px !important

.p-dialog
  border none !important

.p-progress-spinner-circle
  stroke var(--primary-color) !important

.p-checkbox, label
  color $cLabel !important

.p-dialog-header
  border-top-left-radius 12px !important
  border-top-right-radius 12px !important

.p-dialog-content
  border-bottom-left-radius 12px !important
  border-bottom-right-radius 12px !important

.p-button-secondary.p-button-outlined
  border 1px solid $cBorder !important

.p-datatable-thead
  max-height 40px !important

thead th
  height 40px !important

</style>
