<template>
  <div class="wrapper px-4 py-3 border-grey border-left-none flex justify-content-between"
       :class="uiStore.smAndDown?'flex-column align-items-center':''">
    <CustomPaginator @go-to="goTo" v-bind="paginatorOptions"/>
    <div></div>
    <div class="flex" :class="uiStore.smAndDown?'mt-2':''">
      <div class="description-color flex align-items-center f-14">{{ text }}</div>
      <Divider layout="vertical"/>
      <div class="rate flex" >
        <PatchBadDialog  :active="false" :isFooter="true" @clear-selected="clearSelected" :id="props.selectedObjects.map(x=>x.id)"/>
        <PatchMediumDialog  :active="false" :isFooter="true" @clear-selected="clearSelected" :id="props.selectedObjects.map(x=>x.id)"/>
        <PatchPositiveDialog  :active="false" :isFooter="true" @clear-selected="clearSelected" :id="props.selectedObjects.map(x=>x.id)"/>
      </div>
      <Divider layout="vertical"/>
      <DeleteMessagesDialog :ids="props.selectedObjects" />
    </div>
  </div>
</template>

<script setup>
import CustomPaginator from "@/components/CustomPaginator";
import {computed, defineProps} from "vue"
import RateComponent from "@/components/messages/RateComponent";
import {useUiStore} from "@/store";
import DeleteMessagesDialog from "@/components/messages/DeleteMessagesDialog";
import PatchBadDialog from "@/components/messages/PatchBadDialog";
import PatchMediumDialog from "@/components/messages/PatchMediumDialog";
import PatchPositiveDialog from "@/components/messages/PatchPositiveDialog";
import {useMessagesStore} from "@/store/models/messages";
const emits = defineEmits(['go-to','clear'])
const uiStore = useUiStore()
const goTo = (event) => {
  emits('go-to', event)
}

const props = defineProps({
  paginatorOptions: {
    type: Object,
    required: true
  },
  selectedObjects:{
    type:Array,
    required:true
  }
})


const messagesStore = useMessagesStore()
const clearSelected = ()=> emits('clear')

const allMessagesCount = computed(() => {
  return props.paginatorOptions.objectsCount
})
const ass = computed(() => {
  return 5
})
const text = computed(() => {
  if (uiStore.smAndDown) return `${props.selectedObjects.length}/${allMessagesCount.value}`
  return `Выбрано ${props.selectedObjects.length} из ${allMessagesCount.value} сообщений`
})
</script>

<style lang="stylus" scoped>
.wrapper
  width 100%
</style>