<template>
  <Dialog v-bind="$attrs">
    <Dropdown :model-value="currentTheme" @update:modelValue="setTheme($event)" :options="themes"
              optionLabel="title" placeholder="Тема" class="w-full md:w-14rem theme-selector mt-1"/>
  </Dialog>
</template>

<script setup>
import {usePrimeVue} from "primevue/config";
import {ref} from "vue";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";

const PrimeVue = usePrimeVue()
const themes = [
  {title: "bootstrap4-light-blue", theme: "bootstrap4-light-blue"},
  {title: "bootstrap4-dark-blue", theme: "bootstrap4-dark-blue"},
  // {title: "md-light-indigo", theme: "md-light-indigo"},
  // {title: "md-light-deeppurple", theme: "md-light-deeppurple"},
  // {title: "md-dark-indigo", theme: "md-dark-indigo"},
  // {title: "md-dark-deeppurple", theme: "md-dark-deeppurple"},
  // {title: "mdc-light-indigo", theme: "mdc-light-indigo"},
  // {title: "mdc-light-deeppurple", theme: "mdc-light-deeppurple"},
  // {title: "mdc-dark-indigo", theme: "mdc-dark-indigo"},
  // {title: "mdc-dark-deeppurple", theme: "mdc-dark-deeppurple"},
  {title: "tailwind-light", theme: "tailwind-light"},
  {title: "fluent-light", theme: "fluent-light"},
  {title: "lara-light-indigo", theme: "lara-light-indigo"},
  {title: "lara-dark-indigo", theme: "lara-dark-indigo"},
  {title: "lara-light-purple", theme: "lara-light-purple"},
  {title: "lara-dark-purple", theme: "lara-dark-purple"},
  {title: "lara-light-blue", theme: "lara-light-blue"},
  {title: "lara-dark-blue", theme: "lara-dark-blue"},
  {title: "lara-light-teal", theme: "lara-light-teal"},
  {title: "lara-dark-teal", theme: "lara-dark-teal"},
  {title: "saga-blue", theme: "saga-blue"},
  {title: "saga-green", theme: "saga-green"},
  {title: "saga-orange", theme: "saga-orange"},
  {title: "saga-purple", theme: "saga-purple"},
  {title: "vela-blue", theme: "vela-blue"},
  {title: "vela-green", theme: "vela-green"},
  {title: "vela-orange", theme: "vela-orange"},
  {title: "vela-purple", theme: "vela-purple"},
  {title: "arya-blue", theme: "arya-blue"},
  {title: "arya-green", theme: "arya-green"},
  {title: "arya-orange", theme: "arya-orange"},
  {title: "arya-purple", theme: "arya-purple"},
  // {title: "nova", theme: "nova"},
  // {title: "nova-alt", theme: "nova-alt"},
  // {title: "nova-accent", theme: "nova-accent"},
  // {title: "nova-vue", theme: "nova-vue"},
  // {title: "luna-amber", theme: "luna-amber"},
  // {title: "luna-blue", theme: "luna-blue"},
  // {title: "luna-green", theme: "luna-green"},
  // {title: "luna-pink", theme: "luna-pink"},
  // {title: "rhea", theme: "rhea"},
]

const localStorageCurrentThemeSymbol = 'digitalchat-theme';
const currentTheme = ref(themes[0])

function setTheme(theme) {
  PrimeVue.changeTheme(currentTheme.value.theme, theme.theme, 'primevue-theme', () => {
    currentTheme.value = theme
    localStorage.setItem(localStorageCurrentThemeSymbol, theme.theme)
  })
}

if (localStorage.getItem(localStorageCurrentThemeSymbol)) {
  const lastTheme = themes.find(x => x.theme === localStorage.getItem(localStorageCurrentThemeSymbol))
  setTheme(lastTheme)
}
</script>

<style scoped>

</style>