import {defineStore} from "pinia/dist/pinia";
import messagesService from "@/services/messages.service";
import dayjs from "dayjs";
import {useToastsStore} from "@/store/toasts";
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('utc')
const date = (date) => {
    let arr = []
    arr.push(dayjs(date).format('D.MM.YYYY').toString())
    arr.push(dayjs(date).format('hh:mm').toString())
    return arr
}

const text = (text) => {
    if (text.length > 190) return text.slice(0, 190) + '...'
    return text
}

const keywords = (keywords) => {
    return keywords.join(', ')
}


function num_word(value, words) {
    value = Math.abs(value) % 100;
    let num = value % 10;
    if (value > 10 && value < 20) return value + ' ' + words[2];
    if (num > 1 && num < 5) return value + ' ' + words[1];
    if (num === 1) return value + ' ' + words[0];
    return value + ' ' + words[2];
}

const filtersDefault = Object.freeze({
    date_after: dayjs().subtract(1, 'week').toDate(),
    date_before: dayjs().toDate(),
    search: null,
    ordering:null,
    messages: {
        themes: [],
        keywords: [],
        tones: [],
        alertOnly: false,
        hideDuplicates: true,
    },
    chats: {
        activities: [],
        socialMedia: [],
        audience: {
            from: null,
            to: null,
        }
    }

})


export const useMessagesStore = defineStore('keyword-messages', {
    state: () => ({
        useRegion: true,
        extraListParams: {
            show_in_matches: true,
            is_spam: false
        },
        recentMessages: [],
        selected_messages: [],
        filters: JSON.parse(JSON.stringify(filtersDefault)),
        temp_filters: JSON.parse(JSON.stringify(filtersDefault)),
        isNotFound:false,
    }),
    getters: {
        tableObjects() {
            return this.objects?.map((message) => {
                return {
                    date: date(message.date),
                    messenger: message.messenger,
                    keywords: keywords(message.keywords),
                    group: message.group,
                    text: text(message.text),
                    audience: num_word(message.audience, ['участник', 'участника', 'участников']),
                    isAlert: message.is_alert,
                    trueTone: message.true_tone,
                    id: message.id,
                }
            })
        },
        filtersApiDefault() {
            return {
                date_after: dayjs(this.filters.date_after).startOf('day').tz('utc',true).toISOString(),
                date_before: dayjs(this.filters.date_before).endOf('day').tz('utc',true).toISOString(),
                query: this.filters.search,
                keywords__themes: this.filters.messages.themes,
                keywords: this.filters.messages.keywords,
                tones: this.filters.messages.tones.map(x=>x.value),
                alertOnly: this.filters.messages.alertOnly,
                show_in_matches: this.filters.messages.hideDuplicates,
                activities: this.filters.chats.activities,
                socialMedia: this.filters.chats.socialMedia,
                audience_after: this.filters.chats.audience.from,
                audience_before: this.filters.chats.audience.to,
                ordering:this.filters.ordering
            }
        }

    },
    actions: {
        clearSelected() {
            this.selected_messages = []
        },
        discardFilters() {
            this.filters = JSON.parse(JSON.stringify(filtersDefault))
            this.temp_filters = JSON.parse(JSON.stringify(filtersDefault))
        },
        applyFilters() {
            this.filters = {...this.temp_filters}
        },
        PUT_ONE_RECENT({id, data}) {
            const foundedIdx = this.recentMessages.findIndex(x => x.id === id)
            this.recentMessages[foundedIdx] = data
        },
        async loadMessagesList(extra) {
            const toastsStore = useToastsStore()
            console.log('loading')
            if (!extra?._hiddenReload) {
                this.RESET_OBJECTS()
                this.LIST_LOADING()
            }
            this.service.list(await this.buildListParams({...this.filtersApiDefault, ...extra})).then((res) => {
                if ('isNotFound' in this ) {
                    if (res.data.results.length===0) {
                        toastsStore.addToast({title:'Ошибка',text:'Ничего не найдено', type:'error'})
                    }
                    else this.isNotFound = false
                }
                this.OBJECTS_LOADED(res.data.results)
                this.SET_TOTAL_PAGES(res.data.total_pages)
                this.SET_COUNT(res.data.count)
                this.LIST_LOADED()
                console.log('messages loaded')
            })
        },
    },
    model: {
        service: messagesService,
    },
    paginator: {},

})