<template>
  <div class="card flex justify-content-center">
    <Button class="p-3"  style="width:40px; height: 40px" icon="pi" :text="!props.active" :disabled="id.length===0" :outlined="props.active||isFooter" rounded
            :severity="props.active?'':'secondary'" @click="visible = true">
      <img alt="Нейтрально"
           :src="props.active?'/img/icons/rate/medium-blue.svg':isFooter?'/img/icons/rate/medium.svg':'/img/icons/rate/medium-disabled.svg'"/>
    </Button>

    <Dialog v-model:visible="visible" modal class="border-round" header="Header"
            :style="{ width: '400px' }">
      <template #header>
        <img alt="Нейтрально" src="/img/icons/rate/medium-filled.svg"/>
      </template>
      <div class="mb-5 label-color f-20 fw-500">Отметить как нейтрально</div>
      <div class="flex gap-2">
        <Button @click="cancel" class="w-50 flex justify-content-center label-color" outlined
                severity="secondary" label="Отмена"></Button>
        <Button @click="apply" class="w-50 flex justify-content-center">Подтвердить</Button>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import {ref} from "vue";
import messagesService from "@/services/messages.service";
import {Tones} from "@/enums/filters/tones";
import {useMessagesStore} from "@/store/models/messages";
const emits=defineEmits(['clear-selected'])
const messagesStore = useMessagesStore()
const props = defineProps({
  active: {
    type: Boolean,
    required: true,
  },
  id: {
    type: Array,
   required: true,
  },
  isFooter:{
    type:Boolean,
    default:false
  }
})
const visible = ref(false);
const cancel = () => {
  visible.value = false
}


const apply = async () => {
  for (let i = 0; i < props.id.length; i++) {
    const response = await messagesService.patch(props.id[i], {true_tone: Tones.NEUTRAL}, {serializer: 'list'});
    messagesStore.PUT_ONE({id: response.data.id, data: response.data})
    messagesStore.PUT_ONE_RECENT({id: response.data.id, data: response.data})

  }
  if (props.isFooter) emits('clear-selected')
  visible.value = false
}

</script>

<style lang="stylus" scoped>

</style>