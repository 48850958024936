<template>
  <div class="sidebar-wrapper" @mouseover.prevent="setSideBar(true)" @mouseleave="setSideBar(false)"
       :class="uiStore.smAndDown&&!uiStore.isSidebarActive?'d-none':''" v-click-outside="closeIfOpenedSidebar"
  >
    <div class="flex flex-column sidebar" :class="uiStore.isSidebarActive?'sidebar-active':''">
      <div class="menu flex">
        <Button icon="pi pi-bars" style="width:40px !important; height:50px !important; padding: 8px 24px !important"
                class="p-button-text"></Button>
<!--        <img v-if="uiStore.isSidebarActive" class="ml-3" :class="uiStore.isSidebarActive?'':''" height="39"-->
<!--             src="../assets/mobilization.svg" alt=""/>-->
<!--        <div class="fw-500 f-20">-->
<!--          Цифровая мобилизация-->
<!--        </div>-->
        <MobilizationLogo v-show="uiStore.isSidebarActive" class="ml-2"/>
      </div>
      <div class="flex flex-column justify-content-between actions">
        <div class="flex flex-column align-items-center">
          <Button @click="$router.push({name: 'Dashboard'})" icon="pi pi-chart-line f-20" label="Дашборд" text
                  class=" w-full" :class="uiStore.isSidebarActive?'':'p-button-icon-only'"></Button>
<!--          <Button icon="pi pi-chart-bar f-20" label="Аналитика" text-->
<!--                  class="w-full" :class="uiStore.isSidebarActive?'':'p-button-icon-only'"></Button>-->
          <Button @click="$router.push({name: 'Messages'})" icon="pi pi-comment f-20"
                  label="Сообщения" text class="w-full"
                  :class="uiStore.isSidebarActive?'':'p-button-icon-only'"></Button>
          <Button @click="$router.push({name: 'Chats'})" icon="pi pi-users f-20"
                  label="Чаты" text class="w-full"
                  :class="uiStore.isSidebarActive?'':'p-button-icon-only'"></Button>

          <Divider/>
          <Button  disabled icon="pi pi-users f-20" label="Сотрудники" text
                  class=" w-full" :class="uiStore.isSidebarActive?'':'p-button-icon-only'"></Button>
        </div>
        <div class="flex flex-column align-items-center">
          <Divider/>
          <Button icon="pi pi-palette f-20" label="Тема" text 
                  class="w-full"
                  :class="uiStore.isSidebarActive?'':'p-button-icon-only'"
                  @click="showThemesDialog = true"
          />
          <SelectGlobalThemeDialog v-model:visible="showThemesDialog" modal header="Выбор темы"/>

          <Divider/>
          <Button  disabled icon="pi pi-user f-20" label="Профиль" text class="w-full"
                  :class="uiStore.isSidebarActive?'':'p-button-icon-only'"></Button>
          <Button  disabled icon="pi pi-bell f-20" label="Уведомления" text
                  class="w-full" :class="uiStore.isSidebarActive?'':'p-button-icon-only'"></Button>
          <Button  disabled icon="pi pi-cog f-20" label="Настройки" text class=" w-full"
                  :class="uiStore.isSidebarActive?'':'p-button-icon-only'"></Button>
          <Button disabled icon="pi pi-comments f-20 " label="Помощь" text
                  class="w-full" :class="uiStore.isSidebarActive?'':'p-button-icon-only'"></Button>
          <Divider/>
          <div class="flex w-full user">
            <div class="flex align-items-center">
              <div class="avatar fw-500 text-white">
                {{ authStore.fullUser?.username.slice(0, 1) }}
              </div>
              <div class="flex flex-column ml-3" style="width:100px"
                   :class="uiStore.isSidebarActive?'':'d-none'">
                <div class="name">
                  {{ authStore.fullUser?.first_name + ' ' + authStore.fullUser?.last_name.slice(0, 1) }}.
                </div>
                <div class="role">{{ authStore.fullUser?.role }}</div>
              </div>
            </div>
            <Button icon="pi pi-sign-out f-20 " @click="logout"
                    class="p-button-icon-only p-button-text p-button-secondary description-color ml-6"
                    :class="uiStore.isSidebarActive?'':'d-none'"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useAuthStore, useUiStore} from "@/store";
import {router} from "@/router";
import {ref} from "vue";
import SelectGlobalThemeDialog from "@/components/SelectGlobalThemeDialog";
import MobilizationLogo from "@/components/MobilizationLogo";

const uiStore = useUiStore()
const authStore = useAuthStore()

function logout() {
  router.push({name: 'Auth'})
  authStore.logout();
}

function closeIfOpenedSidebar() {
  if (uiStore.isSidebarActive)
    uiStore.isSidebarActive = false
}

function setSideBar(bool) {
  if (uiStore.isSidebarActive !== bool) uiStore.setSidebarActive(bool)
}

const showThemesDialog = ref(false)
</script>

<style lang="stylus" scoped>
@import "../styles/app.styl"
.actions :deep(.p-button-label)
  display none
  padding-left 0


.theme-selector
  display none


.sidebar-active
  .actions :deep(.p-button-label)
    display block
    text-align left
    padding-left 18px
    height 20px

  .user :deep(.p-button-label)
    display block
    padding-left 0

  .theme-selector
    display block

:deep(.p-button-text)
  padding 8px 16px !important

.sidebar-wrapper
  width 93px
  height 100vh

.sidebar
  overflow hidden
  height 100vh
  width 87px
  border-right 1px solid $cBorder
  position absolute
  background var(--surface-0)
  z-index 1000
  left 0
  top 0


.sidebar-active
  width 280px
  animation sidebar 100ms ease-out forwards

.avatar
  border-radius 50%
  width 40px
  height 40px
  display flex
  justify-content center
  align-items center
  background #3D81DB

.menu
  padding 48px 19px
  border-bottom 1px solid $cBorder

.actions
  padding 20px 16px
  height 100%

.pi
  font-size 20px !important

.user
  padding 0 8px

.name
  color $cLabel
  font-size 14px

.role
  color $cDescription
  font-size 14px

@keyframes sidebar {
  from {
    width 110px
  }

  to {
    width 280px
  }
}

</style>