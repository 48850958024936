import {apiService} from "@/services";
import { useCookies } from "vue3-cookies";
import jwtDecode from "jwt-decode";
import axios from "axios";

const API_URL = 'users/';


export const JWT_ACCESS = "jwt";
export const JWT_REFRESH = "jwt_refresh";
export const COOKIE_DOMAIN = window.location.host.split(".").slice(-2).join(".")


function saveJWT(response) {
    const {cookies} = useCookies()

    if (response.data.access) {
        localStorage.setItem('user', JSON.stringify(response.data));
        cookies.set(
            JWT_ACCESS,
            response.data.access,
            new Date(jwtDecode(response.data.access).exp * 1000),
            null,
            COOKIE_DOMAIN,
        )
        cookies.set(
            JWT_REFRESH,
            response.data.refresh,
            new Date(jwtDecode(response.data.refresh).exp * 1000),
            null,
            COOKIE_DOMAIN,
        )
    }

    return response.data
}


class AuthService {
    sendCode(user) {
        return apiService
            .post(API_URL + 'send-code/', {
                username: user.username,
                password: user.password
            })
            .then(response => {
                return response.data;
            });
    }

    login(user, code) {
        return apiService
            .post(API_URL + 'token/', {
                username: user.username,
                password: user.password,
                code
            })
            .then(saveJWT);
    }

    telegramLogin(user) {
        return apiService
            .post(API_URL + 'token/telegram/', user)
            .then(saveJWT);
    }

    async refresh() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.refresh) {
            try {
                const response = await axios.post((process.env.VUE_APP_API_BASE_URL || '') + API_URL + 'token/refresh/', {
                    refresh: user.refresh
                })
                if (response.data.access) {
                    user.access = response.data.access;
                    localStorage.setItem('user', JSON.stringify(user));
                    // $cookies.set("jwt", response.data.access, '10y');
                    console.log("token refreshed");
                    return response.data.access;
                }
            } catch {
                return null
            }
        }
    }

    logout() {
        const {cookies} = useCookies()
        cookies.remove(JWT_ACCESS, null, COOKIE_DOMAIN)
        cookies.remove(JWT_REFRESH, null, COOKIE_DOMAIN)
        localStorage.clear();
    }
}

export default new AuthService();