<template>
  <div>
    <Toast position="bottom-right" group="bc" >

    </Toast>
  </div>
</template>

<script setup>
import {useToastsStore} from "@/store/toasts";
import {useToast} from "primevue/usetoast";
import {watch} from "vue";
import _ from "lodash";

const toast = useToast();
const toastsStore = useToastsStore()
watch(
    () => _.cloneDeep(toastsStore.active),
    () => {
      console.log('ass')
      let cfg = {
        id: toastsStore.activeToast.id,
        severity: toastsStore.activeToast.type,
        summary: toastsStore.activeToast.title,
        detail: toastsStore.activeToast.text,
        life: 2500,
        group: 'bc'
      }

      toast.add(cfg);


        // toastsStore.removeLastToast()

    }
)
</script>

<style scoped>

</style>