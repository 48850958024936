import {defineStore} from "pinia";


export const useToastsStore = defineStore("toasts", {
        state: () => ({
            active: []
        }),
        actions: {
            addToast(toast) {
                this.active.unshift({
                    title: toast.title,
                    text: toast.text,
                    type: toast.type,
                    id: Math.random().toString(16).slice(2)
                })
            },
            removeToast(id) {
                this.active = this.active.filter(x => x.id !== id)
            },
            removeLastToast() {
                this.active = this.active.filter(x => x.id !== this.active[0].id)
            }
        },
        getters: {
            activeToast() {
                return this.active[0]
            }
        }
    }
);